.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin-top: 100px !important;
background-color: #333;
}

.error-content {
    max-width: 600px;
    text-align: center;
    color: white;
}

.error-header {
    color: #F588E3; /* Your brand color */
    font-weight: 600;
}

.error-title {
    font-size: 2.5rem;
    color: #FFFFFF; /* Adjusted to ensure visibility */
    font-weight: 700;
}

.error-description {
    margin-top: 10px;
}

.error-links ul {
    list-style: none;
    padding: 0;
    margin-top: 30px;
}

.navigation-item {
    display: flex;
    gap: 20px;
    padding: 20px 0;
    border-top: 1px solid #E2E8F0; /* Light border color */
}

.navigation-icon {
    width: 56px;
    height: 56px;
    color: white; /* Icon color */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.navigation-details h4 {
    color: #FFFFFF; /* Adjusted to ensure visibility */
    font-weight: 500;
}

.navigation-details p {
    margin-top: 5px;
    font-size: 0.875rem;
    color: #D3D3D3; /* Light color for contrast */
}

.navigation-link {
    color: #F588E3; /* Your brand color */
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
}

.navigation-link:hover {
    color: #D3D3D3; /* Light color for hover */
}
