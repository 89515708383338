

.footer{
  color: #333 !important;
  font-size: 0.9rem;
}
.footer h5{
  font-size: 1.25rem;
  color: #333;
}
.footer ul li{
  font-size: 0.9rem;
}
.footer p{
  font-size: 0.9rem;

}
/* p{
  font-size: 0.9rem;
}
h2{
  font-size: 1.2rem;
} */


@media (max-width: 767px) {
  .custom-width {
      width: 95% !important; /* Adjusted width on small screens */
  }
}

.btn-1{
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background: #18453B;
  background: -webkit-linear-gradient(to right, #18453B, #00AB66);
  background: linear-gradient(to right,  #18453B, #00AB66);
  text-decoration: none;
  color: white !important;
  overflow: hidden;
  border-radius: 1px solid #18453B;
}

@media (max-width: 767px) {
  .custom-width-1 {
      width: 95% !important; /* Adjusted width on small screens */
  }
}
.btn-1:hover{
  text-decoration: none;
  background: #091815;
  background: -webkit-linear-gradient(to right, #18453B, #101d18);
  background: linear-gradient(to right,  #18453B, #101d18);
  color: #fff;
  text-decoration: none;
}


/* Add this to your global CSS file */
@media (max-width: 576px) {
  .custom-toast-container .Toastify__toast {
      font-size: 12px;  /* Adjust font size */
      padding: 8px;     /* Adjust padding */
  }
}
.user-icon {
  list-style-type: none;
  padding: 0;
  margin-right: 20px;
  justify-content: space-evenly;
  align-content: center;
  display: flex;
  font-size: 20px;
}

#user {
  right: 0px;
}

li {
  list-style-type: none;
}

/* Ensure the dropdown and deep dropdown styles remain as desired */
.dropdown ul {
  margin: 0;
  padding: 10px 0;
  background: var(--nav-dropdown-background-color);
  display: block;
  position: absolute;
  visibility: hidden;
  top: 100%; /* Aligns the initial dropdown directly below the trigger */
  opacity: 0;
  transition: 0.3s;
  border-radius: 4px;
  z-index: 99;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.dropdown ul li {
  min-width: 200px;
}

.dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  color: var(--nav-dropdown-color);
  display: block;
}

.dropdown ul a i {
  font-size: 12px;
}

.dropdown ul a:hover,
.dropdown ul .active:hover,
.dropdown ul li:hover > a {
  color: var(--nav-dropdown-hover-color);
}

.dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
}

/* Adjust the deep dropdown to display below the initial dropdown */
.dropdown .dropdown ul {
  top: 100%; /* Positions the deep dropdown directly below the parent dropdown */
  left: 0; /* Aligns the deep dropdown with the left edge of the parent dropdown */
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}

.dropdown .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
}
